<template>
    <div class="">
        <div class="fr con-rig common-righ">
            <!-- <div class="hy-login">
                <div class="login-person clearfix">
                    <div class="fl default-head">
                        <img src="../../../assets/images/default_head.png" alt="" class="dlh-img">
                    </div>
                    <div class="fl loin-btn">
                        登录前显示
                        <div class="dlq dlqxs">
                            <a href="http://home.aopa.org.cn/" target="_blank">会员登录</a>
                            <br>获得完整功能
                        </div>
                        登录后显示
                        <div class="dlh dlhxs" style="display:none;">
                            <div class="name">
                                王适达
                            </div>
                            <div class="address">
                                北京 朝阳
                            </div>
                            <div class="no-infor">
                                <a href="/Column_Content.asp?Column_ID=37667">未读 <span>0</span> 条消息</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="log-sign">
                    登录前显示
                    <div class="dlqxs clearfix">
                        <a href="http://home.aopa.org.cn/" target="_blank">登录</a>
                        <a href="http://home.aopa.org.cn/#/register" target="_blank">注册</a>
                    </div>
                    登录后显示
                    <a href="/Column_Content.asp?Column_ID=37664" class="dlhxs" style="display:none;">
                        <i></i>会员中心 &gt;&gt; </a>
                </div>
                <div class="wdang">
                    <a href="/Column_Content.asp?Column_ID=37704">会员入会指南</a>
                    <a href="/Column_Content.asp?Column_ID=37705">单位会员名单</a>
                </div>
            </div> -->
            <!-- 通用机场查询 -->
            <div class="jccx" style="padding-bottom:20px;height:auto">
                <div class="tit">
                    <a href="https://ga.aopa.org.cn" style="color:#FFF">通用机场信息平台&gt;&gt;</a>
                </div>
                <div class="sear-cont">
                    <a href="https://ga.aopa.org.cn/web_airport/index.html" class="qzjcxx-a"
                        style="background:#52C5CC">通用机场信息发布系统</a>
                    <a href="https://ga.aopa.org.cn/web_airport/html/airport_publish/search_main.html"
                        class="qzjcxx-a" style="background:#169BD5">通用机场信息查询</a>
                    <a href="http://ga.aopa.org.cn/web_airport/evidence_airport.html"
                        class="qzjcxx-a">取证机场信息</a>
                    <div class="ipt-f">
                        <input type="text" name="keyword" class="ipt" id="ssjc-input" placeholder="详情">
                        <button class="sub-plane" id="tj-button"></button>
                    </div>
                </div>
            </div>
            <!-- <div class="aside-ll">
                <a href="http://home.aopa.org.cn/web/#/iframes" style="background-image:url(../../../assets/images/6462.jpg)"
                    target="_blank"></a>
                <a href="https://weidian.com/?userid=1772668524&amp;vc_wfr=wechat_gzh&amp;spider_token=a500"
                    style="background-image:url(../../../assets/images/6465.jpg)" target="_blank"></a>
                <a href="https://www.ljssz.com/fly-box/" style="background-image:url(../../../assets/images/140.png)"
                    target="_blank"></a>
                <a href="http://www.aopa.org.cn/Content_Detail.asp?Column_ID=37687&amp;C_ID=20018474"
                    style="background-image:url(../../../assets/images/6464.jpg)" target="_blank"></a>
            </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: "sidebar",
        components: {},
        data() {
            return {
                
            }
        },
        methods: {
            
        },
        created() {
            // window.addEventListener('scroll', this.toggleStickyHeader);
        },
        mounted() {
            // this.toggleStickyHeader();
        },
        beforeDestroy() {
            // window.removeEventListener('scroll', this.toggleStickyHeader);
        }

    }
</script>

<style scoped lang="scss">
    .ban02 {
        
        .el-carousel{
            width: 100%;
            height: 140px;
            .el-carousel-item{
                width: 100%;
                height: 140px;
            }
        }
        
        .slidimg{
            width: 1200px;
            height: 140px;
        }
    }
    
    
    
</style>